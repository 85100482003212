
.broadcaster{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.video-detail{
    display: flex;
    align-items: center;
    .video-img{
        width: 105px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            max-width:100%;
            max-height: 100%;
        }
    }
    .video-right{
        padding-left: 16px;
        width: 1%;
        flex: 1;
        .video-info {
            line-height: 1.5;
            .video-link {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
                display: block;
                color: #333;
                padding-bottom: 16px;
                /*cursor: pointer;*/
                /*&:hover{*/
                    /*color: #0824CE;*/
                /*}*/
            }
        }
        .tag{
            padding-bottom: 16px;
            display: flex;
            .tag-title {
                display: inline-block;
                min-width: 38px;
                font-size: 12px;
                font-weight: 400;
                color: #999;
            }
            .tag-list{
                display: inline-block;
                max-height: 56px;
                overflow: hidden;
                span{
                    padding: 0 10px;
                    background: rgba(244,244,244,1);
                    border-radius: 12px;
                    display: inline-flex;
                    margin:0 10px 6px 0;
                }
            }
        }
        .publish-time {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999;
        }
    }
}
::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
    background-color: transparent;
}
::v-deep.el-table__body-wrapper{
    flex: 1;
    min-height: 688px;
}

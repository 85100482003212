
 .work-bench-right {
     width: 360px;
     background: #fff;
     margin-left:20px;
         .bench-content-wrapper {
             padding: 15px;
             .bench-head {
                 display: flex;
                 align-items: center;
                 margin-top: 15px;
             }
             .bench-head-avatar {
                 width: 90px;
                 height: 90px;
                 -webkit-border-radius: 50%;
                 -moz-border-radius: 50%;
                 border-radius: 50%;
                 overflow: hidden;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 img {
                     max-width: 100%;
                     max-height: 100%;
                 }
             }
             .bench-head-info {
                 width: 1%;
                 flex: 1;
                 line-height: 1;
                 margin-left: 15px;
                 color: #999;
                 font-size: 14px;
                 overflow: hidden;
                 text-overflow: ellipsis;
                 white-space: nowrap;
                 .name {
                     color: #333;
                     font-size: 16px;
                     /*&:hover {*/
                         /*color: #20C997;*/
                     /*}*/
                 }
                 .account {
                     margin-top: 10px;
                 }
                 .info {
                     margin-top: 10px;
                     span {
                         display: inline-block;
                         margin-right: 6px;
                         &:last-of-type {
                             margin-right: 0;
                         }
                     }
                 }
             }
             .bench-summary {
                 margin-top: 20px;
                 display: flex;
                 .summary-content {
                     width: 1%;
                     flex: 1;
                     text-overflow: ellipsis;
                     display: -webkit-box;
                     -webkit-box-orient: vertical;
                     -webkit-line-clamp: 2;
                     overflow: hidden;
                 }
             }
             .bench-ranking {
                 margin-top: 20px;
                 .ranking-item {
                     display: inline-flex;
                     align-items: center;
                     background: #FF1616;
                     color: #fff;
                     height: 24px;
                     line-height: 24px;
                     border-radius: 12px;
                     padding: 0 15px 0 2px;
                     margin-bottom: 10px;
                     &:last-of-type {
                         margin-bottom: 0;
                         background: #FFB624;
                     }
                     .rank-icon {
                         width: 20px;
                         height: 20px;
                         -webkit-border-radius: 50%;
                         -moz-border-radius: 50%;
                         border-radius: 50%;
                         overflow: hidden;
                         background: #fff url("../../assets/img/image/wb-hg-icon.png") no-repeat center;
                     }
                     .text {
                         margin-left: 10px;
                         .num {
                             margin: 0 4px;
                         }
                     }
                 }
         }
         .bench-data-overview {
             margin-top: 40px;
             .data-title {
                 font-size: 16px;
             }
         }
         .data-list {
             /*font-size: 12px;*/
             color: #999;
             .data-item {
                 display: flex;
                 flex-wrap: wrap;
                 &:first-of-type {
                     padding-bottom: 15px;
                     border-bottom: 1px solid #ededed;
                     .item-num {
                         font-size: 20px;
                     }
                 }
             }
             .item-left {
                 width: 50%;
                 margin-top: 15px;
             }
             .item-right {
                 width: 50%;
                 margin-top: 15px;
                 text-align: right;
             }
             .item-num {
                 font-size: 16px;
                 color: #333;
             }
             .big-green {
                 color: #20C997;
                 font-weight: bold;
             }

         }
     }
     .bench-nodata-wrapper {
         display: flex;
         flex-direction: column;
         align-items: center;
         padding-top: 100px;
         .bench-nodata-img {
             width: 200px;
             img {
                 max-width: 100%;
             }
         }
         .bench-nodata-text {
             margin: 30px 0 40px;
             color: #666;
             font-size: 14px;
         }
     }
}

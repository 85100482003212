
.data{
    padding: 0 25px;
    height: 100%;
    /*overflow: auto;*/
    .data-header{
        .data-title{
            font-size: 14px;
            font-weight: 400;
        }
        .data-content{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            line-height: 35px;
            .data-item{
                display: flex;
                flex-direction: column;
                .g-num{
                    font-size: 24px;
                    font-weight: 700;
                    color: #42d7ab;
                }
                .added-this-week{
                    & i{
                        font-size: 14px;
                        color: #ffb624;
                    }
                    & .num{
                        font-size: 14px;
                        font-weight: 700;
                        color: #ffb624
                    }

                }
            }
            .text{
                color:#656565;
                font-size: 14px;
            }
        }
    }
    .data-trend{
        margin-top: 20px;
    }
    .data-menu{
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 400;
        color: #999;
        text-align: right;
        .item {
            display: inline-block;
            padding: 0 10px;
            border-right: 1px solid #999;
            cursor: pointer;
            &:hover{
                color: #20C997;
            }
            &:last-of-type {
                border-right: 0;
                padding: 0 0 0 10px;
            }
        }
        .selected{
            color: #20C997;
        }
    }
}

